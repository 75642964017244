module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ELSO Automatyka","short_name":"ELSO","description":"Strona wizerunkowa firmy ELSO Automatyka. Prezentująca osiągnięcia firmy oraz źródło informacji i kontakt z firmą.","lang":"pl","display":"standalone","icon":"src/images/icon.png","start_url":"/","background_color":"#ffffff","theme_color":"#3567b7","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7990a46068c82f4c520f2eee75a13a2e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"C:\\Users\\Marek\\OneDrive\\Pulpit\\elso-automatyka/src/intl","languages":["pl"],"defaultLanguage":"pl","redirect":false},
    }]
